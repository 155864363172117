<template lang="pug">
  transition(name="cliptoleft")
    .app-menu(v-if="isMenuOpened")
      .app-menu__logo
        invito-icon
      img.app-menu__decor-left(
        src="@/assets/illustrations/flower.svg")
      img.app-menu__decor-right(
        src="@/assets/illustrations/flower.svg")
      .app-menu__content
        a.app-menu__item(
          v-for="(item, index) in items"
          :key="index"
          :target="item.target"
          :class="{ 'app-menu__item--active': isActive(item) }"
          @click="navigateTo(item)")
          | {{ $t(item.name) }}
      .app-menu__ps
        span made with
        .app-menu__ps-icon
          heart-icon
        span by
        a.link(
          target="_blank"
          href="https://instagram.com/invito.link/") invito.link
</template>

<script>
export default {
  name: 'AppMenu',

  data: () => ({
    items: [
      { name: 'menu.main', path: '/' },
      { name: 'menu.photos', path: '/photos' },
      { name: 'menu.messanger', path: '/', selector: '#telegram' }
    ]
  }),

  computed: {
    isMenuOpened () {
      return this.$store.getters.isMenuOpened
    }
  },

  methods: {
    closeMenu () {
      this.$store.dispatch('closeMenu')
    },

    async navigateTo (item) {
      if (item.path && this.$route.path !== item.path) {
        await this.$router.push(item.path)
      }
      if (item.selector) {
        const element = document.querySelector(item.selector)
        element.scrollIntoView()
      }
      this.$store.dispatch('closeMenu')
    },

    isActive (item) {
      return !item.selector &&
        this.$route.path === item.path
    }
  }
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .app-menu {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    color: $color-primary;
    background-color: $color-background;
    z-index: 10;

    @media screen and (max-width: 800px) {
      position: fixed;
    }

    &__logo {
      position: absolute;
      top: 3rem;
      left: 50%;
      width: 7.2rem;
      height: auto;
      transform: translateX(-50%);
      background-color: $color-background;
      z-index: 1;
    }

    &__decor-left {
      position: absolute;
      height: 36.5rem;
      bottom: -12rem;
      left: -8rem;
      opacity: .7;
      transform: rotate(10deg);
    }

    &__decor-right {
      position: absolute;
      height: 31.2rem;
      opacity: .7;
      transform: rotate(-160deg);
      top: -12rem;
      right: -10rem;
    }

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__item {
      position: relative;
      color: $color-primary;
      display: inline-block;
      font-size: 3rem;
      text-align: center;
      white-space: nowrap;
      text-decoration: none;
      text-transform: uppercase;
      clip-path: polygon(0% 0%, 100% 0%, 100% 0, 0% 0%);
      animation: identifier .7s $ease-in-out-cubic forwards;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * .08}s;
        }
      }

      &:active {
        transform: scale(.94);

        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: .2rem;
          background-color: $color-background;
        }
      }

      &:not(:last-child) {
        margin-bottom: 3rem;
      }

      &:active,
      &:visited {
        color: $color-primary;
      }

      &--active::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: .2rem;
        background-color: $color-primary;
      }
    }

    &__ps {
      position: absolute;
      background-color: $color-background;
      left: 0;
      right: 0;
      bottom: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      z-index: 2;

      &-icon {
        height: 1.2rem;
        width: 1.2rem;
      }

      .link {
        background-color: $color-background;
      }

      *:not(:last-child) {
        margin-right: .5rem;
      }
    }
  }

  @keyframes identifier {
    0% {
      clip-path: polygon(0% 0%, 100% 0%, 100% 0, 0% 0%);
      transform: translateY(4rem);
    }
    100% {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }
</style>
