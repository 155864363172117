<template lang="pug">
  header.app-header
    .app-header__logo(:style="styles")
      invito-icon
    button.btn.btn--ghost.btn--icon(
      @click="openMenu")
      .app-header__menu(:style="styles")
        menu-icon
</template>

<script>

export default {
  name: 'AppHeader',

  methods: {
    openMenu () {
      this.$store.dispatch('openMenu')
    }
  },

  computed: {
    styles () {
      return {
        color: this.$route.path.includes('photos')
          ? '#2B2B27'
          : 'white'
      }
    }
  }
}
</script>

<style lang="scss">
  .app-header {
    width: 100%;
    position: absolute;
    padding: 0 4rem;
    top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 5;

    &__logo {
      width: 7.2rem;
    }

    &__menu {
      width: 3rem;
    }
  }
</style>
