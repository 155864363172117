export default {
  base: {
    yes: 'Yes',
    no: 'No',
    children: 'KIDS',
    and: 'and',
    dear: 'Dear',
    dear_boy: 'Dear',
    dear_girl: 'Dear',
    you: 'you',
    you_2: 'you'
  },
  intro: {
    text_1: 'Love never fails...',
    text_2: '1 Corinthians 13:8'
  },
  menu: {
    main: 'HOME',
    photos: 'PHOTOS',
    colors: 'WEDDING COLORS',
    messanger: 'Telegram'
  },
  info: {
    dear: 'Dear',
    you: 'you',
    text_1: 'We gladly invite you to the celebration<br> dedicated to the creation of our family,<br> which will take place on',
    text_2: '05 February 2022',
    text_4: 'We cannot imagine this joyful day<br> without you',
    text_5: 'For your convenience, we have<br> created a website with detailed<br> information about our wedding.',
    text_7: 'keep scrolling :)'
  },
  address: {
    text_1: 'Ceremony',
    text_2: 'Minsk, st. Gursky, 48<br> Church "Grace"<br> Beginning at 13:00',
    text_3: 'see on the map',
    text_4: 'Banquet',
    text_5: 'v. Sennitsa, st. Embankment, 1<br> Estate "Eden"<br> Beginning at 15:30'
  },
  rsvp: {
    text_1: 'Ceremony',
    text_2: 'Banquet',
    text_3: 'Will you join us in celebrating?',
    text_4: 'If you will be with children, please<br> indicate the required number<br> of additional seats.',
    text_5: 'What parts of the event<br> are you planning to attend?',
    text_6: 'RSVP no later than december 25, 2021'
  },
  timing: {
    caption: 'TIMING OF THE DAY',
    text_1: 'Wedding ceremony',
    text_2: 'Banquet',
    text_3: 'Break',
    text_4: 'Continuation of the program',
    text_5: 'Fireworks. Happy End <br> (official part)',
    text_6: 'Dances and competitions',
    text_7: 'Happy End v2.0'
  },
  present: {
    text_1: 'Due to our move to another country,<br> we ask you to prefer large boxes<br> to small envelopes 😉',
    text_8: 'We also ask you not to bother yourself<br> with a choice of a bouquet but instead you can<br> bring kids’ toys, which will later be passed on<br> to children in an orphanage',
    text_2: 'make a gift',
    text_3: 'ANTON AUREITSEVICH',
    text_4: 'Belgazprombank',
    text_5: '5299 2299 2542 1434',
    text_6: 'TVERDAIA INGA',
    text_7: 'Emirates NBD',
    text_9: '1015653913401',
    text_10: 'AE810260001015653913401'
  },
  alco: {
    text_1: 'Dear guests, adhering to Christian values,<br> we have decided to refrain from serving alcoholic<br> drinks at our wedding, we ask you to support us<br> in this decision 😌'
  },
  countdown: {
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
    ps: '... and we will be married'
  },
  messanger: {
    text_1: 'For a quick exchange<br> of information, photos and videos<br> between our guests, we created<br> a group in Telegram.',
    text_2: 'group chat'
  },
  no_invitation: {
    text_1: 'to open an invitation,<br> take advantage of your<br> unique link :)'
  },
  colors: {
    text_1: 'WE WILL BE GRATEFUL IF YOU<br> SUPPORT THE COLOR Palette<br> OF OUR PARTY'
  },
  photos: {
    text_1: 'here will be our<br> wedding photos and videos'
  }
    // our_story: {
  //   text_1: 'Our love story',
  //   text_2: 'Demyan',
  //   text_3: '“Every morning I wake up, I get so excited to know that I am marrying Ilona. Never have I loved someone as deeply as I love her. She is my treasure, my joy, and my life. There is no one else I would want to live out the rest of my life with other than her”',
  //   text_4: 'Ilona',
  //   text_5: 'He kindled a great feeling in my heart, I love him and I want to always be with him. Only with Demyan I am happy, only with him I feel completely free, and most loved!'
  // },
  // getintouch: {
  //   text_1: 'If you want to take part<br> in the wedding program,<br> you can contact Demyan',
  //   text_2: 'i want to take part'
  // },
}
