export default {
  base: {
    yes: 'Да',
    no: 'Нет',
    children: 'Дети',
    and: 'и',
    dear: 'Дорогие',
    dear_boy: 'Дорогой',
    dear_girl: 'Дорогая',
    you: 'тебя',
    you_2: 'вас'
  },
  intro: {
    text_1: 'Любовь<br> никогда не перестает...',
    text_2: '1-е Коринфянам 13:8'
  },
  menu: {
    main: 'главная',
    photos: 'фотографии',
    colors: 'палитра цветов',
    messanger: 'Telegram'
  },
  info: {
    dear: 'Дорогие',
    you: 'вас',
    text_1: 'С радостью приглашаем {you}<br> на праздник, посвященный<br> созданию нашей семьи,<br> который состоится:',
    text_2: '05 февраля 2022 года',
    text_4: 'И мы не представляем этот день<br> без вас - самых близких и<br> дорогих нам людей!',
    text_5: 'Для вашего удобства мы разработали<br> сайт с подробной информацией<br> о нашем празднике.',
    text_7: 'Приятного просмотра :)'
  },
  address: {
    text_1: 'ВЕНЧАНИЕ',
    text_2: 'г. Минск, ул. Гурского, 48<br> Церковь "Благодать"<br> Начало в 13:00',
    text_3: 'ПОСМОТРЕТЬ НА КАРТЕ',
    text_4: 'БАНКЕТ',
    text_5: 'д. Сенница, ул. Набережная, 1<br> Усадьба «Eden»<br> Начало в 15:30'
  },
  rsvp: {
    text_1: 'ВЕНЧАНИЕ',
    text_2: 'БАНКЕТ',
    text_3: 'сможете ли Вы присоединиться<br> к празднованию вместе с нами?',
    text_4: 'ЕСЛИ БУДЕТЕ С ДЕТЬМИ, ПОЖАЛУЙСТА,<br> УКАЖИТЕ НУЖНОЕ КОЛИЧЕСТВО МЕСТ',
    text_5: 'НА КАКИХ ЧАСТЯХ МЕРОПРИЯТИЯ<br> ПЛАНИРУЕТЕ ПРИСУТСТВОВАТЬ?',
    text_6: 'Просьба сообщить о присутствии<br> до 25 декабря 2021 года'
  },
  timing: {
    caption: 'ТАЙМИНГ ДНЯ',
    text_1: 'Венчание',
    text_2: 'Праздничный банкет',
    text_3: 'Перерыв',
    text_4: 'Продолжение программы',
    text_5: 'Фейрверк. Happy End<br> (официальной части)',
    text_6: 'Танцы, конкурсы',
    text_7: 'Happy End v2.0'
  },
  present: {
    text_1: 'В связи с нашим переездом в другую страну,<br> мы просим Вас предпочесть большим коробкам<br> маленькие конверты 😉',
    text_8: 'Также просим Вас не утруждать себя выбором букета,<br> а вместо цветов Вы можете порадовать нас детской<br> игрушкой, которые в последствии будут переданы<br> деткам в детском доме',
    text_2: 'сделать подарок',
    text_3: 'ANTON AUREITSEVICH',
    text_4: 'Belgazprombank',
    text_5: '5299 2299 2542 1434',
    text_6: 'TVERDAIA INGA',
    text_7: 'Emirates NBD',
    text_9: '1015653913401',
    text_10: 'AE810260001015653913401'
  },
  alco: {
    text_1: 'Уважаемые гости,<br> придерживаясь христианских ценностей,<br> мы решили воздержаться от подачи алкогольных<br> напитков на нашей свадьбе, просим Вас<br> поддержать нас в этом решении. 😌'
  },
  countdown: {
    days: 'дней',
    hours: 'часов',
    minutes: 'минут',
    seconds: 'секунд',
    ps: '... и мы будем женаты!'
  },
  messanger: {
    text_1: 'Для быстрого обмена<br> информацией, фото и видео<br> между нашими гостями<br> мы создали группу<br> в Telegram.',
    text_2: 'добавиться'
  },
  no_invitation: {
    text_1: 'чтобы открыть приглашение<br> воспользуйтесь своей уникальной<br> ссылкой =)'
  },
  colors: {
    text_1: 'БУДЕМ ПРИЗНАТЕЛЬНЫ, ЕСЛИ ВЫ<br> ПОДДЕРЖиТЕ ЦВЕТОВУЮ ГАММУ<br> НАШЕГО ПРАЗДНИКА'
  },
  photos: {
    text_1: 'здесь будут наши<br> свадебные фото и видео'
  }
  // our_story: {
  //   text_1: 'Наша история',
  //   text_2: 'ДЕМЬЯН',
  //   text_3: '"Каждое утро я просыпаюсь счастливым от того, что женюсь на Илоне. Никогда я не любил кого-то так сильно, как люблю ее. Она мое сокровище, моя радость и моя жизнь. Нет никого, с кем бы я хотел прожить остаток своей жизни, кроме нее"',
  //   text_4: 'ИЛОНА',
  //   text_5: 'Он зажег в моем сердце большое чувство, я люблю его и хочу всегда быть рядом. Только с Демьяном я счастлива, только с ним чувствую себя полностью свободной, счастливой и самой любимой!'
  // },
  // getintouch: {
  //   text_1: 'Если вы хотите принять участие<br> в свадебной программе, вы можете<br> связаться с Демьяном',
  //   text_2: 'хочу принять участие'
  // },
}
